<template>
    <div class="d-flex flex-column overflow-hidden h-100">
        <vue-custom-scrollbar
            class="app-content-padding flex-grow-1 overflow-hidden h-100"
        >
            <ts-page-title
                :title="$t('shiftManagement.pageTitle')"
                :breadcrumb="[
                    { text: $t('home'), href: '/' },
                    {
                        text: $t('shiftManagement.pageTitle'),
                        href: '/admin/shift-managements'
                    },
                    {
                        text: $t('edit'),
                        active: true
                    }
                ]"
            />
            <ts-panel>
                <ts-panel-wrapper>
                    <div class="row">
                        <div class="col-md-12 tw-pt-5">
                            <shift
                                v-model="model"
                                :validate="errors"
                                class="tw-h-full"
                            />
                        </div>
                        <div class="col-md-12 tw-pb-5">
                            <shift-working-hour
                                v-model="model"
                                :validate="errors"
                                class="tw-h-full"
                            />
                        </div>
                    </div>
                </ts-panel-wrapper>
            </ts-panel>
        </vue-custom-scrollbar>
        <Spin size="large" fix v-if="loading"> </Spin>
        <div
            id="footer"
            class="app-footer m-0 tw-flex tw-justify-end tw-space-x-2 tw-sh"
            style="background-color: #e9ecef"
        >
            <ts-button
                @click.prevent="$router.push({ name: 'shift-management' })"
                class="btn-gray"
            >
                {{ $t("cancel") }}</ts-button
            >
            <ts-button
                :waiting="waiting"
                @click.prevent="onUpdate"
                color="primary"
            >
                {{ $t("update") }}</ts-button
            >
        </div>
    </div>
</template>

<script>
import Shift from "./components/shift.vue";
import ShiftWorkingHour from "./components/shift-working-hour.vue";
import AppOptions from "@/config/AppOptions.vue";
import { mapActions, mapState } from "vuex";
import { Errors } from "form-backend-validation";
import { isEmpty } from "lodash";

export default {
    name: "shiftManagementEdit",
    components: { Shift, ShiftWorkingHour },
    data() {
        return {
            loading: false,
            waiting: false,
            waiting_new: false,
            errors: new Errors(),
            model: {
                shift_name: null,
                shift_type: null,
                minute_allow: null,
                details: []
            }
        };
    },
    computed: {
        ...mapState("humanResource/shiftManagement", ["edit_data"])
    },
    created() {
        AppOptions.appContentFullHeight = true;
        AppOptions.appContentClass = "p-0";
    },
    methods: {
        ...mapActions("humanResource/shiftManagement", ["find"]),
        async fetchResource() {
            this.loading = true;
            await this.find(this.$route.params.id).catch(error => {
                this.notice({ type: "error", text: error.message });
            });
            this.setEditData();
            this.loading = false;
        },
        async onUpdate() {
            this.errors = new Errors();
            this.waiting = true;
            this.$store
                .dispatch("humanResource/shiftManagement/update", {
                    id: this.model.shift_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.$router.push({ name: "shift-management" });
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        async setEditData() {
            if (!isEmpty(this.edit_data)) {
                this.model.shift_id = this.edit_data.shift_id;
                this.model.shift_name = this.edit_data.shift_name;
                this.model.shift_type = this.edit_data.shift_type;
                this.model.minute_allow = this.edit_data.minute_allow;
                this.edit_data.details.forEach(detail => {
                    this.model.details.push({
                        shift_id: detail.shift_id,
                        keyword: detail.keyword,
                        day_id_from: detail.day_id_from,
                        day_id_to: detail.day_id_to,
                        first_in: detail.first_in,
                        first_out: detail.first_out,
                        second_in: detail.second_in,
                        second_out: detail.second_out,
                        total_hour: detail.total_hour,
                        record_id: detail.record_id
                    });
                });
            }
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "SHIFT MANAGEMENT",
                desc: not.text
            });
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchResource();
        });
    },
    beforeRouteLeave(to, from, next) {
        AppOptions.appContentFullHeight = false;
        AppOptions.appContentClass = "";
        next();
    }
};
</script>
<style scoped>
.text-nowrap {
    white-space: nowrap;
}
</style>
